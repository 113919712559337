import { defineStore } from 'pinia'
import type { CompanyDtoRes, ProjectDtoRes } from '@/types/api';
import * as storage from '~/stores/localStorage'

const key = 'useCompanyStore'

export const useCompanyStore = defineStore(key, () => {


  const _data = ref<CompanyDtoRes>(null)
  const _loading = ref<boolean>(false)
  const dateFetched = ref<Date>(null)
  const _projects = ref<ProjectDtoRes[]>(null)
  
  const data = computed<CompanyDtoRes>(() => _data.value)
  const projects = computed<ProjectDtoRes[]>(() => _projects.value)
  const loading = computed<boolean>(() => _loading.value)
  
  const init = () => {
    const data = load(key)
  
    if(data){
      _data.value = data.payload ? data.payload.company : null 
      _projects.value = data.payload ? data.payload.projects : null
      dateFetched.value = new Date(data.timestamp)
      get()
      return true
    }
      
    return get() 
  }
  
  const get = async() => {
    _loading.value = true
    try{
      const {data, error} = await useApi('/company')
      
      if(error.value){
        _loading.value = false
        throw error 
      }
      
      if(typeof data?.value?.id === 'undefined'){
        _loading.value = false
        return false
      }
      
      const {data: projectsData, error: projectsError} = await useApi('/project/company/{company_id}', {
        path: {
          company_id: data.value.id
        }
      })
      
      if(projectsError.value){
        _loading.value = false
        throw projectsError.value
      }
      
      _data.value = data.value
      _projects.value = projectsData.value
      
      dateFetched.value = new Date()
      _loading.value = false
      
      storage.set(key, {company: _data.value, projects: _projects.value})
      
    }
    catch(err){
      throw err
    }
  }

  return { data, projects, get, init, loading }
  
})